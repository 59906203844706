<template>
  <v-app>
    <v-main>
      <v-container>
        <v-layout class="d-flex justify-center align-center historyTitle">
          <v-flex xs12 class="text-center">
            <h1>Historial de consultas</h1>
          </v-flex>
        </v-layout>
        <v-layout class="d-flex justify-center align-center">
          <v-img
            alt="Elca Logo"
            class="shrink mr-2"
            contain
            src="@/assets/logo2.png"
            transition="scale-transition"
            width="150"
          />
        </v-layout>
        <v-layout class="datosPersonales">
          <v-flex xs5>
            <div v-html="dataClient"></div>
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex xs5>
            <div v-html="dataElca"></div>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs12 md2>
            <v-text-field
              autocomplete="off"
              v-model="anamnesis.fecha"
              label="Fecha de evaluación"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex xs12 md9>
            <v-select
              v-model="anamnesis.prof"
              :items="this.$store.state.professionals"
              item-value="id"
              item-text="nombre"
              label="Evaluador"
            ></v-select>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs12>
            <v-textarea
              filled
              auto-grow
              v-model="anamnesis.situacionfamiliar"
              label="Situación familiar"
            ></v-textarea>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs12>
            <v-textarea
              filled
              auto-grow
              v-model="anamnesis.medicacionactual"
              label="Medicación actual"
            ></v-textarea>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs12>
            <v-textarea
              filled
              auto-grow
              v-model="anamnesis.patologias"
              label="Patologías diagnosticadas"
            ></v-textarea>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs12>
            <v-textarea
              filled
              auto-grow
              v-model="anamnesis.tratamientoanterior"
              label="¿Tratamiento psicológico o psiquiátrico anterior?"
            ></v-textarea>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs12>
            <v-textarea
              filled
              auto-grow
              v-model="anamnesis.motivoconsulta"
              label="Breve descripción del motivo de consulta"
            ></v-textarea>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs12>
            <v-textarea
              filled
              auto-grow
              v-model="anamnesis.antecedentes"
              label="¿Antecedentes de patologías psicológicas-psiquiátricas/médicas/orgánicas familiares?"
            ></v-textarea>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs12>
            <v-textarea
              filled
              auto-grow
              v-model="anamnesis.pesotalla"
              label="Peso pasado y actual y talla. Pensamientos asociados a la variación de peso, objetivo personal en este ámbito."
            ></v-textarea>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs12>
            <v-textarea
              filled
              auto-grow
              v-model="anamnesis.alimentacion"
              label="Hábitos alimentarios pasados y actuales"
            ></v-textarea>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs12>
            <v-textarea
              filled
              auto-grow
              v-model="anamnesis.liquidos"
              label="Ingesta de líquidos (frecuencia y cantidad)"
            ></v-textarea>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs12>
            <v-textarea
              filled
              auto-grow
              v-model="anamnesis.consumos"
              label="Consumo de tabaco/alcohol/otras drogas"
            ></v-textarea>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs12>
            <v-textarea
              filled
              auto-grow
              v-model="anamnesis.atracones"
              label="Existencia de atracones (subjetivos u objetivos). ¿En qué circustancias son más frecuentes? ¿Conductas contrarestantes y frecuencia? (Vómitos, Purgantes, Restricción, Deporte...)"
            ></v-textarea>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs12>
            <v-textarea
              filled
              auto-grow
              v-model="anamnesis.autoimpuesto"
              label="¿Disminución autoimpuesta de la ingesta?"
            ></v-textarea>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs12>
            <v-textarea
              filled
              auto-grow
              v-model="anamnesis.evitarcomer"
              label="¿Alimentos que evita comer? Motivo"
            ></v-textarea>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs12>
            <v-textarea
              filled
              auto-grow
              v-model="anamnesis.conductas"
              label="¿Conductas extrañas con la comida? (trocear mucho, lentitud, escurrir, esconder, dejar comida...)"
            ></v-textarea>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs12>
            <v-textarea
              filled
              auto-grow
              v-model="anamnesis.frecuencias"
              label="Frecuencia con la que se piensa en comida y peso a lo largo del día"
            ></v-textarea>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs12>
            <v-textarea
              filled
              auto-grow
              v-model="anamnesis.culpas"
              label="Culpa tras las comidas"
            ></v-textarea>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs12>
            <v-textarea
              filled
              auto-grow
              v-model="anamnesis.miedos"
              label="Miedo a perder el control cuando come"
            ></v-textarea>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs12>
            <v-textarea
              filled
              auto-grow
              v-model="anamnesis.sintomastca"
              label="Sintomas físicos relacionados con el TCA"
            ></v-textarea>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs12>
            <v-textarea
              filled
              auto-grow
              v-model="anamnesis.menstruacion"
              label="Historia de menstruación"
            ></v-textarea>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs12>
            <v-textarea
              filled
              auto-grow
              v-model="anamnesis.insatisfaccion"
              label="Insatisfacción con su cuerpo. Descripción de aspectos negativos-positivos"
            ></v-textarea>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs12>
            <v-textarea
              filled
              auto-grow
              v-model="anamnesis.miedoengordar"
              label="¿Miedo a engordar? ¿deseo de adelgazar?"
            ></v-textarea>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs12>
            <v-textarea
              filled
              auto-grow
              v-model="anamnesis.actividades"
              label="Actividades de su vida que no puede disfrutar debido a este problema"
            ></v-textarea>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs12>
            <v-textarea
              filled
              auto-grow
              v-model="anamnesis.comparaciones"
              label="Comparaciones con otros, síntomas de irritabilidad y tristeza"
            ></v-textarea>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs12>
            <v-textarea
              filled
              auto-grow
              v-model="anamnesis.apoyo"
              label="Apoyo de familia y amigos. Comprensión del problema"
            ></v-textarea>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs12>
            <v-textarea
              filled
              auto-grow
              v-model="anamnesis.otros"
              label="Otras anotaciones"
            ></v-textarea>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs12>
            <v-textarea
              filled
              auto-grow
              v-model="anamnesis.tratamientorecomendado"
              label="Tratamiento recomendado"
            ></v-textarea>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs12>
            <v-textarea
              filled
              auto-grow
              v-model="anamnesis.valoracion"
              label="Valoración en caso de recomendación de UDD"
            ></v-textarea>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs12>
            <v-textarea
              filled
              auto-grow
              v-model="anamnesis.respuesta"
              label="Respuesta de la paciente y familiares y por qué"
            ></v-textarea>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs12>
            <v-textarea
              filled
              auto-grow
              v-model="anamnesis.ambitosocial"
              label="Ámbito social"
            ></v-textarea>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs12>
            <v-textarea
              filled
              auto-grow
              v-model="anamnesis.ambitofamiliar"
              label="Ámbito familiar"
            ></v-textarea>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs12>
            <v-textarea
              filled
              auto-grow
              v-model="anamnesis.ambitolaboral"
              label="Ámbito laboral-académico"
            ></v-textarea>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs12>
            <v-textarea
              filled
              auto-grow
              v-model="anamnesis.ambitoafectivo"
              label="Ámbito afectivo"
            ></v-textarea>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs12>
            <v-data-table
              :headers="headersReportHistory"
              :items="items"
              item-key="id"
              hide-default-footer
              :items-per-page="items.length"
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td>{{ item.fecha }}</td>
                  <td>{{ item.tipo }}</td>
                  <td class="text-pre-wrap">{{ item.comentario }}</td>
                  <td class="text-pre-wrap">{{ item.tratamiento }}</td>
                </tr>
              </template>
            </v-data-table>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import genericReload from "./genericReload";
export default {
  name: "HistoryDocument",
  data: () => ({
    items: [],
    anamnesis: [],
    dataClient: "",
    dataElca: "",
    headersReportHistory: [
      { text: "Fecha", value: "fecha" },
      { text: "Tipo", value: "tipo" },
      { text: "Consulta", value: "comentario" },
      { text: "Tratamiento", value: "tratamiento" },
    ],
  }),
  async mounted() {
    const a = this.$route.query.a;
    const data = JSON.parse(localStorage.getItem("items"));
    this.anamnesis = JSON.parse(localStorage.getItem("anamnesis"));

    const responseClient = await genericReload(
      a,
      "api/client/" + data[0].idCliente
    );
    const jsonClient = await responseClient.json();
    this.dataClient =
      jsonClient[0].apellidos +
      ", " +
      jsonClient[0].nombre +
      "<br>" +
      jsonClient[0].email +
      "<br>" +
      jsonClient[0].telefono +
      "<br>" +
      jsonClient[0].domicilio;

    const responseCenter = await genericReload(a, "api/centers/1");
    const jsonCenter = await responseCenter.json();
    this.dataElca =
      jsonCenter[0].nombre +
      "<br>" +
      jsonCenter[0].email +
      "<br>" +
      jsonCenter[0].telefono +
      "<br>" +
      jsonCenter[0].direccion +
      "<br>" +
      jsonCenter[0].localidad +
      " " +
      jsonCenter[0].cp;

    this.items = data;

    this.printDocument();
  },
  methods: {
    printDocument() {
      setTimeout(() => {
        window.print();
        window.close();
      }, 500);
    },
  },
};
</script>

<style scoped>
.historyTitle {
  margin-bottom: 20px;
}
.datosPersonales {
  margin-top: 70px;
  margin-bottom: 70px;
}
</style>
